import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { Notification } from "../models/Notification";

const emptyNotifications: Notification[] = [];

const initialState = {
    notifications: emptyNotifications,
}

const notifications = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        loadNotifications: (state, action) => {
            state.notifications = action.payload
        },
        receiveNewNotification: (state, action) => {
            state.notifications = [ action.payload, ...state.notifications ]
        },
        markAllNotificationsViewed: (state) => {
            state.notifications = state.notifications.map(notification => ({ ...notification, viewed: true }))
        },
        removeNotification: (state, action) => {
            state.notifications = state.notifications.filter(notification => notification.id !== action.payload)
        }
    }
})

export const { loadNotifications, receiveNewNotification,
    markAllNotificationsViewed, removeNotification } = notifications.actions

export const notificationsSelector = (state: RootState) => state.notifications.notifications
export const newNotificationsCounterSelector = (state: RootState) =>
    state.notifications.notifications?.filter(notification => !notification.viewed).length

export default notifications.reducer
