import React, { useContext } from "react";
import FolderBreadcrumb from "./FolderBreadcrumb";

import { ProjectFileManagementContext } from "../../contexts/ProjectFolderManagementContextProvider";
import FolderTree from "./FolderTree";
import { FilesComponent } from "../files-component";

function FileManagementMain(props: {
  projectId: string | undefined;
  isShowFilePathInTable: boolean;
  isShowFolderTreeView: boolean;
  files: any[];
  handleUpdateSelectedFiles: (files: any[]) => void;
  selectedFiles: any[];
  onDoubleClickRow: Function;
  setIsDisableCombineReportsButton: Function;
  setIsDisabledCompareButton: Function;
  refreshTable: Function;
  clareFileSearchText: Function;
}) {
  const {
    folderStructure,
    activeFolderPath,
    expandedFolderIdList,
    handleToggleFolder,
    handleSetActiveNode,
  } = useContext(ProjectFileManagementContext);

  return (
    <div
      style={{
        display: "flex",
        gap: 20,
        marginTop: 20,
        marginLeft: 10,
        marginRight: 10,
      }}
    >
      {props.isShowFolderTreeView && (
        <div style={{ minWidth: 200 }}>
          {folderStructure.map((node) => (
            <FolderTree
              key={node.id}
              node={node}
              expandedFolderIdList={expandedFolderIdList}
              handleToggleFolder={handleToggleFolder}
              handleSetActiveNode={handleSetActiveNode}
            />
          ))}
        </div>
      )}

      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {props.isShowFolderTreeView && (
          <FolderBreadcrumb
            activeFolderPath={activeFolderPath}
            handleSetActiveNode={(id: string) => handleSetActiveNode(id)}
          />
        )}

        <FilesComponent
          key={`files-component-${props.projectId}-${props.files.length}-${props.isShowFolderTreeView}`}
          isShowFilePathInTable={props.isShowFilePathInTable}
          projectId={props.projectId}
          files={props.files}
          selectedFiles={props.selectedFiles}
          handleUpdateSelectedFiles={props.handleUpdateSelectedFiles}
          onDoubleClickRow={props.onDoubleClickRow}
          setIsDisableCombineReportsButton={
            props.setIsDisableCombineReportsButton
          }
          setIsDisabledCompareButton={props.setIsDisabledCompareButton}
          refreshTable={props.refreshTable}
          handleSetActiveNode={(id: string) => {
            handleSetActiveNode(id);
            props.clareFileSearchText("");
          }}
        />
      </div>
    </div>
  );
}

export default FileManagementMain;
