import React, { useMemo, useState } from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Down arrow
import ArrowRightIcon from "@mui/icons-material/ArrowRight"; // Right arrow
import { Node } from "../../services/folderDragAndDrop";
import { acceptedFileTypes } from "../../constants";
import "./folderAndFilesSelect.scss";

interface InputChangeEvent extends React.ChangeEvent<HTMLInputElement> {}

interface DropEvent extends React.DragEvent<HTMLDivElement> {}

export interface FileNode extends File {
  size: number;
  isNameValid?: boolean;
  isFileExistsInDraft?: boolean;
  isFileExistsInProject?: boolean;
  folderId?: number;
  projectId?: number;
}
export interface FolderItem {
  id: string;
  name: string;
  type: string;
  relativePath?: string;
  children: (FolderItem | FileNode)[];
}

export type schemaNode = FolderItem | FileNode;

function FolderAndFileSelectionNode({
  node,
  projectFileList = [],
  handleRemoveNode = () => {},
}: {
  node: schemaNode;
  projectFileList: (FileNode | Node)[];
  handleRemoveNode: (node: schemaNode) => void;
}) {
  const [isOpen, setIsOpen] = useState(true);

  const children = (() => {
    if ("children" in node) {
      const child = node?.children ?? [];
      return child;
    }
    return [];
  })();

  const isNameValid = "isNameValid" in node && node.isNameValid;

  const isFileExistsInProject =
    "isFileExistsInProject" in node && node.isFileExistsInProject;

  const isFileExistsInDraft =
    "isFileExistsInDraft" in node && node.isFileExistsInDraft;
  return (
    <>
      <div
        style={{
          cursor: "grab",
          display: "flex",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        {node.type === "folder" ? (
          <>
            <span
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </span>
            <span>📁 {node.name}</span>
            <span
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                color: "red",
              }}
              onClick={() => {
                handleRemoveNode(node);
              }}
            >
              ✖️
            </span>
          </>
        ) : acceptedFileTypes.includes(node.type) ? (
          <>
            <span
              style={{
                marginLeft: 34,
                color:
                  !isNameValid || isFileExistsInProject || isFileExistsInDraft
                    ? "darkred"
                    : "inherit",
              }}
            >
              📄 {node.name}
              <span>
                {(!isNameValid ||
                  isFileExistsInProject ||
                  isFileExistsInDraft) && (
                  <span
                    style={{
                      color: "red",
                      marginLeft: "10px",
                      fontSize: "12px",
                    }}
                  >
                    {!isNameValid
                      ? "File name is not valid."
                      : isFileExistsInProject
                      ? " - File already exist in project."
                      : isFileExistsInDraft
                      ? " - File already exist in draft."
                      : ""}
                  </span>
                )}
              </span>
            </span>
            <span
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                color: "red",
              }}
              onClick={() => {
                handleRemoveNode(node);
              }}
            >
              ✖️
            </span>
          </>
        ) : null}
      </div>

      {isOpen && children && children.length > 0 && (
        <div style={{ marginLeft: "20px" }}>
          {children.map((child, index) => (
            <FolderAndFileSelectionNode
              key={`${child.name}_${index}`}
              node={child}
              projectFileList={projectFileList}
              handleRemoveNode={handleRemoveNode}
            />
          ))}
        </div>
      )}
    </>
  );
}

export const getFileListFromSchema = (schema: schemaNode[]) => {
  const files: FileNode[] = [];

  const traverse = (nodes: schemaNode[]) => {
    for (const node of nodes) {
      if (node.type !== "folder" && "size" in node) {
        files.push(node);
      }

      if ("children" in node && node.children && node.children.length > 0) {
        traverse(node.children);
      }
    }
  };

  traverse(schema);
  return files;
};

type FolderAndFilesSelectProps = {
  isFolderSelectedForUploading: boolean;
  projectFileList: Node[];
  handleSubmitSchema: (schema: schemaNode[]) => void;
  handleCancel: () => void;
};

function FolderAndFilesSelect({
  isFolderSelectedForUploading = true,
  projectFileList = [],
  handleSubmitSchema = () => {},
  handleCancel = () => {},
}: FolderAndFilesSelectProps) {
  const [selectedSchema, setSelectedSchema] = React.useState<schemaNode[]>([]);

  const fileListFromSchema = useMemo(() => {
    const files = getFileListFromSchema(selectedSchema);
    return files;
  }, [selectedSchema]);

  const {
    isDisableSubmitButton,
    isFileExistsInProject,
    isFileExistsInDraft,
    isAllFileNameValid,
  } = (() => {
    let isFileExistsInDraft = false;
    let isFileExistsInProject = false;
    let isAllFileNameValid = true;

    fileListFromSchema.forEach((fileItem) => {
      if (fileItem.isFileExistsInDraft) {
        isFileExistsInDraft = true;
      }

      if (fileItem.isFileExistsInProject) {
        isFileExistsInProject = true;
      }

      if (!fileItem.isNameValid) {
        isAllFileNameValid = false;
      }
    });

    const isDisableSubmitButton =
      selectedSchema.length === 0 || !isAllFileNameValid;

    return {
      isDisableSubmitButton,
      isFileExistsInProject,
      isFileExistsInDraft,
      isAllFileNameValid,
    };
  })();

  /** get unique id */
  const getUniqueId = () => {
    const uniqueId = new Date().toISOString().replace(/[:.]/g, "-");
    return uniqueId;
  };

  const checkFileAlreadyExists = (
    node: FileNode
  ): { isFileExistsInProject: boolean; isFileExistsInDraft: boolean } => {
    const isFileExistsInProject = projectFileList.find(
      (file) => file?.name === node.name
    );

    const isFileExistsInDraft = fileListFromSchema.find(
      (file) => file?.name === node.name
    );

    return {
      isFileExistsInDraft: !!isFileExistsInDraft,
      isFileExistsInProject: !!isFileExistsInProject,
    };
  };

  /** remove node */
  const handleRemoveNode = (node: schemaNode) => {
    // const updatedSchema = schema;
    const updatedSchema = _.cloneDeep(selectedSchema);
    let removedNode: schemaNode | null = null;
    const removeNodeRecursion = (nodes: schemaNode[]) => {
      for (let i = 0; i < nodes.length; i++) {
        if (
          node &&
          nodes[i] &&
          "id" in node &&
          "id" in nodes[i] &&
          (node as FolderItem).id === (nodes[i] as FolderItem).id
        ) {
          removedNode = nodes.splice(i, 1)[0];
        } else if (
          node &&
          nodes[i] &&
          node.name === nodes[i].name &&
          node.type === nodes[i].type &&
          "size" in node &&
          "size" in nodes[i] &&
          (node as FileNode).size === (nodes[i] as FileNode).size
        ) {
          removedNode = nodes.splice(i, 1)[0];
        }

        if (
          nodes[i] &&
          "children" in nodes[i] &&
          Array.isArray((nodes[i] as FolderItem).children) &&
          removeNodeRecursion((nodes[i] as FolderItem).children)
        ) {
          return true;
        }
      }
    };
    removeNodeRecursion(updatedSchema);

    setSelectedSchema(updatedSchema);
  };

  const handleDropFolderAndFiles = async (
    event: DropEvent
  ): Promise<schemaNode[]> => {
    event.preventDefault();
    const items = Array.from(event.dataTransfer.items || []);

    const traverseFileTree = async (
      item: any,
      path: string = ""
    ): Promise<schemaNode | undefined> => {
      if (item.isFile) {
        const file = await new Promise<FileNode>((resolve) =>
          item.file(resolve)
        );
        if (!acceptedFileTypes.includes(file.type)) {
          return;
        }

        const { isFileExistsInDraft, isFileExistsInProject } =
          checkFileAlreadyExists(file);

        file.isFileExistsInDraft = isFileExistsInDraft;
        file.isFileExistsInProject = isFileExistsInProject;

        if (file.name.includes("%")) {
          file.isNameValid = false;
        } else {
          file.isNameValid = true;
        }

        return file;
      } else if (item.isDirectory) {
        const dirReader = item.createReader();
        const entries = await new Promise<any[]>((resolve) =>
          dirReader.readEntries(resolve)
        );
        if (entries.length === 0) {
          return {
            id: `${item.name}_${getUniqueId()}`,
            name: item.name,
            type: "folder",
            relativePath: `${path}${item.name}/`,
            children: [],
          };
        } else {
          const children = await Promise.all(
            entries.map((entry) =>
              traverseFileTree(entry, `${path}${item.name}/`)
            )
          );
          return {
            id: `${item.name}_${getUniqueId()}`,
            name: item.name,
            type: "folder",
            relativePath: `${path}${item.name}/`,
            children:
              (children.filter(
                (child) => child !== undefined
              ) as schemaNode[]) || [],
          };
        }
      }
      return undefined;
    };

    const structure = await Promise.all(
      items.map((item) => traverseFileTree(item.webkitGetAsEntry()))
    );

    const schema = structure.filter(
      (item) => item !== undefined
    ) as schemaNode[];

    setSelectedSchema((prevState) => [...prevState, ...schema]);

    return schema;
  };

  const handleSelectFolderAndFiles = (
    event: InputChangeEvent
  ): schemaNode[] => {
    const fileObject = event.target.files;
    const files: FileNode[] = Array.from(fileObject || []);
    const root: schemaNode[] = [];
    // Helper function to find or create folder
    function findOrCreateFolder(
      children: schemaNode[],
      folderName: string
    ): schemaNode {
      let folder = children.find(
        (item) => item.name === folderName && item.type === "folder"
      );
      if (!folder) {
        folder = {
          id: `${folderName}_${getUniqueId()}`,
          name: folderName,
          type: "folder",
          children: [],
        };
        children.push(folder);
      }
      return folder;
    }
    // Iterate through each file and build the tree
    files.forEach((file: FileNode) => {
      const parts = file.webkitRelativePath.split("/"); // Split path into parts
      let currentLevel = root;
      parts.forEach((part, index) => {
        if (index !== parts.length - 1) {
          /* It's a folder */
          const folder = findOrCreateFolder(currentLevel, part);
          if ("children" in folder) {
            currentLevel = folder.children;
          }
        } else {
          /* It's a file */
          if (!acceptedFileTypes.includes(file.type)) {
            return;
          }
          const { isFileExistsInDraft, isFileExistsInProject } =
            checkFileAlreadyExists(file);

          file.isFileExistsInDraft = isFileExistsInDraft;
          file.isFileExistsInProject = isFileExistsInProject;

          if (file.name.includes("%")) {
            file.isNameValid = false;
          } else {
            file.isNameValid = true;
          }
          currentLevel.push(file);
        }
      });
    });

    setSelectedSchema((prevState) => [...prevState, ...root]);
    return root;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "inherit",
        flex: 1,
      }}
    >
      <div
        onDragOver={(event) => {
          event.preventDefault();
          event.currentTarget.style.backgroundColor = "#f0f8ff"; // Highlight on drag over
        }}
        onDragLeave={(event) => {
          event.currentTarget.style.backgroundColor = ""; // Remove highlight on drag leave
        }}
        onDrop={(event) => {
          event.preventDefault();
          event.currentTarget.style.backgroundColor = ""; // Remove highlight on drop
          handleDropFolderAndFiles(event);
        }}
        className="folder-and-file-select-drag-drop"
      >
        {selectedSchema.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              maxHeight: 500,
              overflow: "auto",
              flex: 1,
            }}
          >
            {selectedSchema.map((node, index) => (
              <FolderAndFileSelectionNode
                key={`${node.name}_${index}`}
                node={node}
                projectFileList={projectFileList}
                handleRemoveNode={handleRemoveNode}
              />
            ))}
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: selectedSchema.length > 0 ? "flex-start" : "center",
          }}
        >
          <label
            htmlFor="fileInput"
            className="folder-and-file-select-drag-drop-label"
            style={{
              width: "fit-content",
              backgroundColor:
                selectedSchema.length > 0 ? "#E6E6E6" : "inherit",
              fontSize: selectedSchema.length > 0 ? 14 : 20,
            }}
          >
            <input
              type="file"
              multiple
              onChange={(event) => {
                handleSelectFolderAndFiles(event);
                event.target.value = "";
              }}
              style={{ display: "none" }}
              id="fileInput"
              accept={acceptedFileTypes.join(",")}
              {...(isFolderSelectedForUploading ? { webkitdirectory: "" } : {})}
            />

            {selectedSchema.length > 0
              ? "Add more"
              : "Click here or drag and drop"}
          </label>
        </div>

        {selectedSchema.length > 0 && isAllFileNameValid && (
          <Box sx={{ textAlign: "center" }}>
            <Button
              type="button"
              sx={{ background: "#2484FF", fontSize: 14 }}
              variant="contained"
              disabled={isDisableSubmitButton}
              onClick={() => {
                handleSubmitSchema(selectedSchema);
              }}
            >
              Submit
            </Button>
          </Box>
        )}

        {(isFileExistsInProject || !isAllFileNameValid) && (
          <div
            style={{
              borderTop: "1px solid #ccc",
              marginTop: 16,
              paddingTop: 4,
            }}
          >
            {!isAllFileNameValid && (
              <p style={{ color: "#FF0000", marginLeft: 10 }}>
                Filename contains invalid character (%). Please rename before
                uploading.
              </p>
            )}
            {isFileExistsInDraft && (
              <p style={{ color: "#FF0000", marginLeft: 10 }}>
                Some files already exist in this draft. To continue, either
                rename these files or remove the existing ones.
              </p>
            )}

            {isFileExistsInProject && (
              <p style={{ color: "#FF0000", marginLeft: 10 }}>
                Files already exist in this project. To continue, either rename
                these files or replace the existing ones.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default FolderAndFilesSelect;
