import { TextField, useTheme,} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import { useNavigate } from "react-router";
import { MoreActionsComponent } from "../../../components/more-actions-component";
import { useEventHandler } from "../../../hooks/handle-events";
import baseline_arrow_back from "../../../provisional_icons/baseline-arrow-back.png";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useCustomModal } from "../../modals/custom-message-modal";
import { ProjectEditNameAndStudyIDWizard } from "./wizards/project-edit-name-studyID";
import { ProjectFilterBar } from "../../../components/project-filters";
import { CustomDatePicker } from "../../../components/custom-date-picker";
import { blueIconColor, DESCRIPTION_ROW, statusList, StatusType } from "../../../constants";
import { StatusMenuComponent } from "../../../components/status-menu-component";
import { getApiProjectsById, putApiProjectsProjectStatusDatesById } from "../../../services/project";
import { Project } from "../../../models/Project";
import { TaskListComponent } from "../../../components/project/task-list-component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hook";
import { hideProgressLine, showProgressLine } from "../../../redux/progress-line";
import { showSnackbar } from "../../../redux/snackbar";
import {
    actionBarIsVisibleSelector, 
    setIsTemplate,
    setProjectId, setProjectName,
    setShowToolbarAfterDeletion
} from "../../../redux/action-bar";
import { loadProject, projectSelector, selectProject } from "../../../redux/projects";
import { UsersAvatarList } from "../../../components/users-avatar-list";
import moment from "moment-timezone";
import {useLocation, useParams} from "react-router-dom";
import { ShareProjectMenu } from "../../../components/project/share-project-menu";
import { useAuthService } from "../../../contexts/auth-context";
import { customFieldsSelector, customFieldsValuesSelector } from "../../../redux/custom-fields";
import { ProjectDetailsComponent } from "./project-details";
import Button from "@mui/material/Button";
import {getExcelFileNoCache, startUploadingToS3, uploadFileUsingS3} from '../../../components/file-management'
// import {FilesComponent} from "../../../components/files-component";
import {StyledCloseIconUploader} from "../../../components/close-button";
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import {ListAltOutlined, Refresh} from "@mui/icons-material";
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { ExportProjectComponent } from "../../../components/project/export-project";
import {
    deleteApiFileById,
    getApiFilesByProjectId,
    getFilesByIds, 
} from "../../../services/files";
import {filesSelector, loadFiles} from "../../../redux/file-management";
import { FileState } from "../../../models/FileState";
import { putApiFileOpenStatusById } from "../../../services/files";
import {LinkFilesToTaskModal} from "../../modals/modal-content/link-files-to-task-modal";
import { NlpSuggestAnnotations } from "../../modals/modal-content/NlpSuggestAnnotations";
import { ArchiveProjectComponent, RemoveProjectComponent, RestoreProjectComponent } from "../../../components/archived-project-actions";
import { userSettingsSelector } from "../../../redux/userSettings";
import { saveAs } from 'file-saver';
import './single-project.scss'
import {TaskList} from "../../../models/TaskList";
import PopOver from "../../../components/common/popover";
import { getApiFolderSchemaByProjectId } from "../../../services/folder";
import FileManagementMain from "../../../components/fileManagement/FileManagementMain";
import { UploadFileOrFolder } from "../../../components/fileManagement/FileOrFolderUploadModal";
import { ProjectFileManagementContext } from "../../../contexts/ProjectFolderManagementContextProvider";
import {CombineBatchReportsModal} from "../../modals/modal-content/combineBatchReportsModal";
import { fileRefreshCountSelector } from "../../../redux/file-management";
import { Divider } from "@mui/material";
import { Node } from "../../../services/folderDragAndDrop";
export type ProjectTabSelected = "Task Listing" | "Project Details | File Management";

export async function markDocsAsOpened(publicUrls: any[], files: FileState[]) {
    for (const url of publicUrls) {
        const fileName = getFileNameFromURL(url)
        const file = files.find(file => file.name === fileName);
        if (file) {
            await putApiFileOpenStatusById(file.id, { isDocOpen: true });
        } else {
            throw new Error("File not found");
        }
    }
}

export function navToDocs(publicUrls: any[], files: FileState[], showModal: any, nav: any, projectId?: number, taskId?: number, initialTaskStatus?: string, isRestoring?: boolean, tag?: string, shouldCompare?: boolean) {
    if(publicUrls) {
        const currentlyOpenedDocs = files.filter(file => file.isDocOpen);
        if (currentlyOpenedDocs.length > 0) {
            const filesRequestedButAlreadyInUse: FileState[] = [];
            for (const url of publicUrls) {
                const fileName = getFileNameFromURL(url)
                const file = files.find(file => file.name === fileName);
                if (file) {
                    if (currentlyOpenedDocs.some(openedDoc => openedDoc.id === file.id)) {
                        filesRequestedButAlreadyInUse.push(file);
                    }
                } else {
                    throw new Error("File not found");
                }
            }
        }

        markDocsAsOpened(publicUrls, files);
        let href = shouldCompare ? `/app/user/workflow/projects/${projectId}/web-viewer` : `/app/user/workflow/projects/${projectId}/viewer`;
        if(shouldCompare) {
            href += `?compare=true`
        }
        nav(`${href}`, {
            state: {
                files: publicUrls,
                shouldCompareVersion: localStorage.getItem("versionUrls") !==null,
                taskId: taskId,
                initialTaskStatus: initialTaskStatus,
                isRestoring: isRestoring,
                tag: tag
            }
        });
    }
};

export function getFileNameFromURL(url: string) {
    try {
        //These URLS look like this
        //https://bucket-name.s3.amazonaws.com/test/22/documents/Document%20Name.pdf?AWSAccessKeyId=SDFJKSDK

        //Get everything after the final slash
        const fileNameWithAccessKey = url.split("/").pop();
        //Remove the question mark and everything after it. That's used in a URL to denote extra information
        const fileName = fileNameWithAccessKey?.split("?")[0];
        //Finally, use this method to remove encoded characters, like %20 for space.
        return decodeURIComponent(fileName!);
    } catch (e) {
        console.log(`Couldn't get file name from this URL ${url}`)
        console.log(e)
        return undefined;
    }
}

export function SingleProject() {
    const auth = useAuthService();
    const theme = useTheme();
    const nav = useNavigate();
    const dispatch = useAppDispatch();
    const { showModal, hideModal } = useCustomModal();
    const location = useLocation();
    const { onMouseEventHandler, onLeaveMouseEventHandler } = useEventHandler();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [totalPages, setTotalPages] = useState(0);
    const [scopePages, setScopePages] = useState(0);
    const [showShareProject, setShowShareProject] = useState(false);
    const [showBatchReportsOnly, setShowBatchReportsOnly] = useState(false);
    const lastUrlElement = window.location.pathname.split("/").pop()
    const defaultTab = lastUrlElement === 'file-management' ? 'File Management' : 'Task Listing';
    const ref = useRef<any>(null);
    const [projectTabSelected, setProjectTabSelected] = useState<"Task Listing" | "Project Details" | "File Management">(defaultTab);
    const { projectId, taskListId } = useParams();
    const id = +projectId!;
    const project: Project | undefined = useAppSelector((state) => projectSelector(state, id));
    const refreshCount = useAppSelector((state) => fileRefreshCountSelector(state));
    const actionBarIsVisible = useAppSelector((state) => actionBarIsVisibleSelector(state));
    const customFields = useAppSelector((state) => customFieldsSelector(state));
    const customFieldsValues = useAppSelector((state) => customFieldsValuesSelector(state));
    const [taskListObject, setTaskListObject] = useState<Record<string, TaskList>>({});
    const [selectedTaskList, setSelectedTaskList] = useState<any>({})
    const [selectedTaskListKey,setSelectedTaskListKey] = useState('')
    const projectFiles: FileState[] = useAppSelector(filesSelector);
    const userSettings = useAppSelector((state) => userSettingsSelector(state));
    const dateFormat = userSettings?.regional?.dateFormat ?? 'YYYY/MM/DD';

    const [isShowFolderTreeView, setIsShowFolderTreeView] = useState(true);
    const [fileSearchText, setFileSearchText] = useState<string>("");
    const searchInputRef = useRef<HTMLInputElement | null>(null); // Add a ref for the search input
    const searchDebounceTimeout = useRef<any>(null);
    
    /* File Management */    
    const {
        folderStructure,
        allFileObjectList,
        fileObjectList,
        selectedFiles,
        isDisableCompareButton,
        setIsDisabledCompareButton,
        setSelectedFiles,
        handleInitialRendering,
    } = useContext(ProjectFileManagementContext);
    
    const [isDisableCombineReportsButton, setIsDisableCombineReportsButton] = useState<boolean>(true);
   
    const noFilesSelected = selectedFiles.length === 0;
    
    const fileList = useMemo(() => {
      let fileObjectListResult: Node[] = [];

      if (fileSearchText) {
        const traverse = (node: Node, path: Node[]) => {
          if (
            node.type === "file" &&
            node.name.toLowerCase().includes(fileSearchText.toLowerCase())
          ) {
            fileObjectListResult.push({
              ...node,
              path: path, // Add the folder path as an array
            });
          }

          if (node.children && node.children.length > 0) {
            node.children.forEach((child) => {
              traverse(child, [
                ...path,
                {
                  id: node.id,
                  name: node.name,
                  type: node.type,
                  folderId: node.folderId,
                },
              ]); // Append the current folder name to the path
            });
          }
        };

        folderStructure.forEach((node) => {
          traverse(node, []); // Start with an empty path
        });
      } else if (!isShowFolderTreeView) {
        fileObjectListResult = allFileObjectList;
      } else {
        fileObjectListResult = fileObjectList;
      }

      if (showBatchReportsOnly) {
        const batchFiles = fileObjectListResult.filter(
          (file) =>
            file.category === "Batch Report" ||
            file.category === "Multi-Document Batch Report"
        );

        return batchFiles;
      }

      return fileObjectListResult;
    }, [
      folderStructure,
      fileObjectList,
      isShowFolderTreeView,
      showBatchReportsOnly,
      fileSearchText,
    ]);

    /* File Management */


    /* project task */
    useEffect(() => {
        if (project && project.taskLists && customFieldsValues.length > 0) {
            const cfv = customFieldsValues.filter(cfv => project.taskLists!.some(tl => tl.id === cfv.taskListId));
            const totalPagesCF = customFields.find(cf => cf.shortName === "nrPages");
            const scopePagesCF = customFields.find(cf => cf.shortName === "pagesInScope");
            if (totalPagesCF) {
                setTotalPages(cfv.reduce((prevValue: number, value) =>
                    prevValue + value.customFieldsValues.filter(c => c.customFieldId === totalPagesCF.id)
                        .reduce((totalValue: number, c) => totalValue + (+c.valueString! || 0), 0)
                    , 0));
            }
            if (scopePagesCF) {
                setScopePages(cfv.reduce((prevValue: number, value) =>
                    prevValue + value.customFieldsValues.filter(c => c.customFieldId === scopePagesCF.id)
                        .reduce((totalValue: number, c) => totalValue + (+c.valueString! || 0), 0)
                    , 0));
            }
        }
    }, [customFieldsValues, customFields, projectTabSelected]);


    useEffect(() => {
      if (window.location.pathname.split("/").pop() !== "file-management") {
        setProjectTabSelected("Task Listing");
      }
      return () => {
        dispatch(setProjectName(undefined));
        dispatch(setProjectId(-1));
      };
    }, [projectId]);

    useEffect(() => {
      const listObject: any = {};
      project?.taskLists
        ?.filter((tl) => (taskListId ? tl.id === +taskListId : true))
        .map((tl) => (listObject[`${tl.id}`] = tl));
      setTaskListObject(listObject);
      const keys = Object.keys(listObject);
      let index =
        selectedTaskListKey !== ""
          ? keys.indexOf(selectedTaskListKey)
          : keys.length - 1;
      keys.length > 0 && setSelectedTaskList(listObject[keys[index]]);
      if (ref?.current) {
        ref?.current.resetSelectedTaskList(keys[index]);
      }
    }, [project, selectedTaskListKey]);
    /* project task */


    useEffect(() => {
      refreshTable();
      return () => {};
    }, [refreshCount]);

    useEffect(() => {
      reset();
    }, [location.pathname]);

    useEffect(() => {
      fetchProject();
    }, [id]);

    useEffect(() => {
      dispatch(setShowToolbarAfterDeletion(false));
    }, [projectTabSelected]);


    async function refreshTable() {
      getFilesByProjectId(id);
      getFolderSchemaByProjectId(id);
    }

    /**@description updates project dates and status by project id */
    const updateProjectDatesAndStatus = async (params ={}) => {
        const body ={
            ...project!.projectStatusDates,
            ...params
        }
        dispatch(showProgressLine())
        putApiProjectsProjectStatusDatesById(project!.id, body)
        .then(() => {
            dispatch(hideProgressLine())
            fetchProject()
        })
        .catch(() => {
            dispatch(hideProgressLine());
            dispatch(showSnackbar({ message: "Error setting date", type: "error" }))
        })
    }

    /**@description get doc files by project id */
    const getFilesByProjectId = async (projectId = id) => {
      try {
        const files = await getApiFilesByProjectId(projectId);
        dispatch(loadFiles(files));
        return files;
      } catch (error) {
        dispatch(
          showSnackbar({ message: "Error loading files!", type: "error" })
        );
      }
    };

    /**@description get schema of folders structure by project id */
    const getFolderSchemaByProjectId = async (projectId = id) => {
      try {
        const folderSchema = await getApiFolderSchemaByProjectId(projectId);
        handleInitialRendering(folderSchema);
        return folderSchema;
      } catch (error) {
        dispatch(
          showSnackbar({ message: "Error loading files!", type: "error" })
        );
      }
    };

    /**@description get project detail by id */
    const getProjectsById = async (projectId = id) => {
      const controller = new AbortController();
      getApiProjectsById(projectId, controller.signal)
        .then((project) => dispatch(loadProject(project)))
        .then(() => {
          dispatch(hideProgressLine());
          dispatch(setProjectId(projectId));
          dispatch(selectProject(id));
          dispatch(setIsTemplate(false));
        })
        .catch(() => {
          dispatch(hideProgressLine());
          dispatch(
            showSnackbar({ message: "Error loading project!", type: "error" })
          );
        });
    };

    const fetchProject = (selectedTaskListKey?: string) => {
      setSelectedTaskListKey(selectedTaskListKey ?? "");
      dispatch(showProgressLine());

      getFilesByProjectId(id);
      getFolderSchemaByProjectId(id);
      getProjectsById(id);
    };

    const setDate = (value: Date | null, type: "start" | "due") => {
        const body = type === "start" ?
            {
                ...project!.projectStatusDates,
                startDate: value ? moment(value).format("YYYY-MM-DD 00:00:00") : undefined
            }
            :
            {
                ...project!.projectStatusDates,
                dueDate: value ? moment(value).format("YYYY-MM-DD 00:00:00") : undefined
            }

        updateProjectDatesAndStatus(body)
    }

   async function getUrlsFromSelectedRows({
     tableSelectedFiles = selectedFiles,
     shouldCompare = false,
   } = {}) {
     let urlList: string[] = [];
     let selectedFileList = [];

     if (localStorage.getItem("versionUrls") !== null && shouldCompare) {
       selectedFileList = JSON.parse(localStorage.getItem("versionUrls")!);
     } else {
       selectedFileList = await Promise.all(
         tableSelectedFiles.map(async (fileInfo: any) => {
           const newFileInfo = { ...fileInfo };
           const fileId = fileInfo?.file?.fileId;
           const filesData = await getFilesByIds([fileId]);
           newFileInfo.file.url = filesData[0].url;
           return newFileInfo;
         })
       );
     }

     // @ts-ignore
     selectedFileList.forEach((row) => {
       // @ts-ignore
       urlList.push(
         localStorage.getItem("versionUrls") !== null && shouldCompare
           ? row
           : row.file.url
       );
     });
     return urlList;
   }

    function getIdsFromSelectedRows(tableSelectedFiles = selectedFiles) {
      const idList = tableSelectedFiles.map(
        (fileInfo: any) => fileInfo?.file?.fileId
      );

      return idList;
    }

    const handleUpdateSelectedFiles = (files: any[]) => {
        setSelectedFiles(files);
    }

    async function combineBatchReports() {
        const urls = await getUrlsFromSelectedRows()
        showModal(() => CombineBatchReportsModal(urls, project), {})
    }

    const goToDocs = async ({
      tableSelectedFiles = selectedFiles,
      shouldCompare = false,
    }: { tableSelectedFiles?: any[]; shouldCompare?: boolean } = {}) => {
      const publicUrls = await getUrlsFromSelectedRows({
        tableSelectedFiles: tableSelectedFiles,
        shouldCompare: shouldCompare,
      });

      for (const url of publicUrls) {
        const filepath = url.split("?")[0];
        if (!filepath.endsWith("pdf")) {
          dispatch(
            showSnackbar({
              message: "Only PDFs can be opened in Docs",
              type: "error",
            })
          );
          return;
        }
      }

      if (publicUrls) {
        const files = await getFilesByProjectId(id);
        if (files) {
          const projectIdNum = projectId ? parseInt(projectId) : undefined;
          navToDocs(
            publicUrls,
            files,
            showModal,
            nav,
            projectIdNum,
            undefined,
            undefined,
            undefined,
            undefined,
            shouldCompare
          );
        }
      }
    };

    async function handleDelete() {
        for (const fileInfo of selectedFiles){
            const fileId = fileInfo?.file?.fileId
            if(fileId){
                await deleteApiFileById(fileId);
            }
        }
        await refreshTable();
    }

    async function downloadFiles(): Promise<void> {
      try {
        const selectedFileIds = getIdsFromSelectedRows();
        const filesData = await getFilesByIds(selectedFileIds);

        for (const fileData of filesData) {
          const url = fileData?.url;

          const response = await fetch(url!);
          const blob = await response.blob();
          const filename = fileData?.name;
          saveAs(blob, filename);
          console.log(`Downloaded file: ${filename}`);
        }

        console.log("All files downloaded successfully.");
      } catch (error) {
        console.error("An error occurred while downloading files:", error);
        dispatch(
          showSnackbar({ message: "Error Downloading Files!", type: "error" })
        );
      }
      dispatch(hideProgressLine());
    }

    const setStatus = (status: StatusType) => {
        updateProjectDatesAndStatus({status})
    }

    const handleSearchFile =(value: string)=> {
        clearTimeout(searchDebounceTimeout.current);
        searchDebounceTimeout.current = setTimeout(() => {
            setFileSearchText(value);
        }, 500);
    }

    function reset() {
        setIsDisabledCompareButton(true)
        setIsDisableCombineReportsButton(true)
    }


    const renderSwitch = (state: "Task Listing" | "Project Details" | "File Management") => {
        switch (state) {
            case "Task Listing":
                return <div>
                    <ProjectFilterBar projectId={project.id} selectedProjectIds={[]}/>
                    {Object.keys(selectedTaskList).length > 0 &&  <div style={{
                        height: `calc(100vh - 184px - 129px - 60px - 40px${actionBarIsVisible ? " - 60px" : ""})`,
                        borderTop: "1px solid var(--lightGrayishBlue2)", overflowY: "auto"
                    }} id={`project-data-${id}`}>

                        <TaskListComponent ref={ref} projectId={+project.id} projectName={project.name}
                                           taskLists={[selectedTaskList]}
                                           taskListObject={taskListObject}
                                           changeSelectedTask={(id: string)=>setSelectedTaskList(taskListObject[id])}
                                           fetchProject={fetchProject} isTemplate={false} shortView={false}/>
                    </div>
                    }
                </div>
            case "Project Details":
                return <div><ProjectDetailsComponent fetchProject={fetchProject} totalPages={totalPages}
                                                     scopePages={scopePages}/></div>
            case "File Management":
                return <div>
                    <div style={{display: "flex", alignItems: "center", flexWrap: "wrap", gap: 4, margin: 4}}>
                        <span onClick={() => setIsShowFolderTreeView((prevProps) => !prevProps)} style={{cursor: "pointer",}}>
                            { isShowFolderTreeView ? <ListAltOutlined  sx={{fontSize: 30, color: '#217da2'}}/> : <WidgetsOutlinedIcon sx={{fontSize: 30, color: '#217da2'}} /> }
                        </span>
                        <Button
                          onClick={() =>
                            showModal(UploadFileOrFolder, {
                              projectId: projectId!,
                              node: folderStructure[0],
                              onCancel: () => {
                                hideModal();
                              },
                              isFolderSelectedForUploading: false,
                            })
                          }
                          color="secondary"
                          variant="outlined"
                          style={{ width: "fit-content", alignSelf: "center" }}
                        >
                          Upload File(s)
                        </Button>
                        <Button onClick={() => {refreshTable()}}
                            color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}}
                            startIcon={<Refresh/>}>
                            Refresh
                        </Button>
                        <Button onClick={async () => {goToDocs()}}
                            color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}} disabled={noFilesSelected}>
                            Go to DOCS
                        </Button>
                        <Button onClick={combineBatchReports}
                                color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}} disabled={isDisableCombineReportsButton}>
                            Generate Multi-Document Batch Report
                        </Button>
                        {isDisableCompareButton ? <PopOver style={{backgroundColor: '#ddd', color: '#999', opacity: '.7'}} buttonText="Compare Files" content="Select 2 files to enable compare"/> :
                            <Button title={"Select 2 files to enable compare"} onClick={async() => {goToDocs({shouldCompare: true})}}
                                    color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}} disabled={isDisableCompareButton}>
                                Compare Files
                            </Button> }
                        {auth.hasPMRoleAccess() && <Button onClick={() => downloadFiles()}
                                color="secondary" variant="outlined"
                                style={{width: "fit-content", alignSelf: "center"}}
                                startIcon={<DownloadIcon/>} disabled={noFilesSelected}>
                            Download
                        </Button>}
                        <Button onClick={() => handleDelete()}
                            color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}} disabled={noFilesSelected}
                            startIcon={<DeleteIcon/>}>
                            Archive
                        </Button>
                        <Button onClick={() => {showModal(() => LinkFilesToTaskModal(project, id, getIdsFromSelectedRows(), fetchProject), {})}
                        }
                                color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}} disabled={noFilesSelected}
                        >
                            Link to a task
                        </Button>
                        {
                            auth.hasNlpAccess() &&
                                <Button onClick={() => {
                                    showModal(() => NlpSuggestAnnotations(project, getIdsFromSelectedRows(), fetchProject, reset), {})
                                    }
                                }
                                        color="secondary" variant="outlined" style={{width: "fit-content", alignSelf: "center"}} disabled={noFilesSelected}
                                >
                                    Suggest Annotations
                                </Button>
                        }
                        <TextField
                          label="Search Files"
                          variant="outlined"
                          size="small"
                          inputRef={searchInputRef} // Attach the ref to the input
                          onChange={(e) => handleSearchFile(e.target.value)}
                          style={{ marginLeft: 8 }}
                        />
                    </div>
                    <input type={"checkbox"} checked={showBatchReportsOnly} onChange={(e) => setShowBatchReportsOnly(e.target.checked)}/> Show Batch Reports Only
                    <Divider />
                    <FileManagementMain 
                        isShowFilePathInTable={!!fileSearchText}
                        isShowFolderTreeView={isShowFolderTreeView && !fileSearchText}
                        projectId={projectId} 
                        files={fileList} 
                        selectedFiles={selectedFiles}
                        handleUpdateSelectedFiles={handleUpdateSelectedFiles}
                        onDoubleClickRow={async(row:any) => {goToDocs({tableSelectedFiles: [row]})}}
                        setIsDisableCombineReportsButton={setIsDisableCombineReportsButton} 
                        setIsDisabledCompareButton={setIsDisabledCompareButton}
                        refreshTable={refreshTable}
                        clareFileSearchText={() => {
                          setFileSearchText(""); // Reset the state
                          if (searchInputRef.current) {
                            searchInputRef.current.value = ""; // Clear the input box using the ref
                          }
                        }}
                    />
                </div>
        }};



        let menuItems = [
            <MenuItem
                key={0}
                onMouseEnter={onMouseEventHandler}
                onMouseLeave={onLeaveMouseEventHandler}
                onClick={() => {
                    setAnchorEl(null);
                    showModal(ProjectEditNameAndStudyIDWizard, {project, refreshList: fetchProject});
                }}>
                Edit project name and ID
            </MenuItem>,
            <MenuItem
                key={1}
                onMouseEnter={onMouseEventHandler}
                onMouseLeave={onLeaveMouseEventHandler}
                onClick={async () => {
                    setAnchorEl(null);
                    setShowShareProject(auth.hasPMRoleAccess());
                }}>
                Share project
            </MenuItem>,
            <MenuItem
                key={2}
                onMouseEnter={onMouseEventHandler}
                onMouseLeave={onLeaveMouseEventHandler}
                onClick={() => {
                    setAnchorEl(null);
                }}>
                Export report
            </MenuItem>,
            <ExportProjectComponent project={project} totalPages={totalPages} scopePages={scopePages} key={3}
                onMouseEventHandler={onMouseEventHandler}
                onLeaveMouseEventHandler={onLeaveMouseEventHandler}
                setAnchorEl={setAnchorEl} />
        ]

        if ((auth.hasAdminRoleAccess() || auth.hasPMRoleAccess()) && !project.isArchived) {
            menuItems.push(
                <ArchiveProjectComponent selectedProjectIds={[project.id]} isMenuItem={true} key={4} />
            )
        }

        if ((auth.hasAdminRoleAccess() || auth.hasPMRoleAccess()) && project.isArchived) {
            menuItems.push(
                <RestoreProjectComponent selectedArchivedProjectIds={[project.id]} isMenuItem={true} key={5} />
            )

            menuItems.push(
                <RemoveProjectComponent selectedArchivedProjectIds={[project.id]} isMenuItem={true} key={6} />
            )
        }

        return project === undefined ? <></> :
            <div className="single-project-template-wrapper">
                <div className="single-project-template-box"
                     style={{
                         width: `calc(100vw - 256px - ${showShareProject ? '266px' : '64px'}`,
                         marginRight: showShareProject ? 14 : 32
                     }}>
                    <div className="single-project-template-header">
                        <div className="single-project-template-back">
                            <img alt="back" src={baseline_arrow_back} onClick={() => nav("/app/user/workflow/projects")}
                                 className="single-project-temaplate-back-button"/>
                            <span className="single-project-template-name">{project?.name}</span>
                            {project.isArchived &&
                                <span className="single-project-template-archived">
                                    {`ARCHIVED ${moment(project.archivedAt).format(dateFormat)}`}
                                </span>
                            }
                        </div>
                        <div style={{flexGrow: 1}}></div>
                        {auth.hasPMRoleAccess() && <div style={{marginTop: 3}}>
                            <MoreActionsComponent id={0} anchorEl={anchorEl} setAnchorEl={setAnchorEl}
                                                  menuHorizontalPosition="right" menuItems={menuItems}/>
                        </div>}
                    </div>
                    <div className="single-project-template-horizontal-line"/>
                    <div style={{display: "flex"}}>
                        <div style={{display: "flex"}}>
                            {auth.hasPMRoleAccess() ?
                                <StatusMenuComponent
                                    status={(project.projectStatusDates?.status ?? "NOT_STARTED") as StatusType}
                                    onUpdate={(status) => setStatus(status)} projectStatus={true}/>
                                :
                                <div style={{
                                    display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 24,
                                    letterSpacing: 0.1, color: "white", fontSize: 15, fontWeight: 600, borderRadius: 4,
                                    backgroundColor: statusList[(project.projectStatusDates?.status || "NOT_STARTED") as StatusType].color,
                                    width: 158, height: 40, whiteSpace: "nowrap"
                                }}>
                                    {statusList[(project.projectStatusDates?.status || "NOT_STARTED") as StatusType].name}
                                </div>
                            }
                            <div style={{marginLeft: 24, alignSelf: "flex-end"}}>
                                <CustomDatePicker value={project.projectStatusDates?.startDate ?
                                    new Date(project.projectStatusDates?.startDate) : null}
                                                  setValue={(date) => setDate(date, 'start')} name="START DATE"
                                                  readonly={!auth.hasPMRoleAccess()}
                                                  icon={<CalendarTodayIcon
                                                      style={{color: auth.hasPMRoleAccess() ? blueIconColor : "#E6E7E8"}}/>}/>
                            </div>
                            <div style={{marginLeft: 24, alignSelf: "center"}}>
                                <CustomDatePicker value={project.projectStatusDates?.dueDate ?
                                    new Date(project.projectStatusDates?.dueDate) : null}
                                                  setValue={(date) => setDate(date, 'due')} name="DUE DATE"
                                                  readonly={!auth.hasPMRoleAccess()}
                                                  icon={<EventAvailableIcon
                                                      style={{color: auth.hasPMRoleAccess() ? blueIconColor : "#E6E7E8"}}/>}/>
                            </div>
                        </div>
                        <div style={{flexGrow: 1}}></div>
                        <div style={{display: "flex", alignItems: "center", marginRight: 16}}>
                            <span>Shared with</span>
                            <span style={{marginLeft: 16}}>
                            {project.organizationShare ? "My organization" :
                                <UsersAvatarList users={project.users || []} shortView={true}/>}
                        </span>
                        </div>
                    </div>
                    <div className="single-project-template-horizontal-line" style={{marginTop: 14}}/>
                    <div className="task-details-comment-menu-wrapper" style={{marginBottom: 0}}>
                        <div className="task-details-comment-menu-component"
                             onClick={() => setProjectTabSelected("Task Listing")} style={{
                            color: projectTabSelected === "Task Listing" ? theme.palette.secondary.main : "#414042",
                            paddingLeft: 14,
                            paddingRight: 14,
                            borderBottom: projectTabSelected === "Task Listing" ? `2px solid ${theme.palette.secondary.main}` : ""
                        }}>Task Listing
                        </div>
                        <div className="task-details-comment-menu-component"
                             onClick={() => setProjectTabSelected("Project Details")} style={{
                            color: projectTabSelected === "Project Details" ? theme.palette.secondary.main : "#414042",
                            paddingLeft: 16, paddingRight: 16, borderBottom: projectTabSelected === "Project Details" ?
                                `2px solid ${theme.palette.secondary.main}` : ""
                        }}>Project Details
                        </div>
                        {auth.hasFMRoleAccess() ?
                            <div className="task-details-comment-menu-component"
                                 onClick={() => setProjectTabSelected("File Management")} style={{
                                color: projectTabSelected === "File Management" ? theme.palette.secondary.main : "#414042",
                                paddingLeft: 16,
                                paddingRight: 16,
                                borderBottom: projectTabSelected === "File Management" ?
                                    `2px solid ${theme.palette.secondary.main}` : ""
                            }}>File Management
                            </div> :
                            <div className="task-details-comment-disabled-menu-component"
                            >File Management</div>
                        }
                    </div>
                    {
                        renderSwitch(projectTabSelected)
                    }
                </div>
                {showShareProject &&
                <ShareProjectMenu key={projectId} showUserMenu={setShowShareProject} fetchProject={fetchProject}/>}
            </div>
    }
